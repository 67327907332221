<template>
  <div class="page">
    <section>
      <span class="title">消息通知</span>
      <div class="content">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="全部" name="all">
            <!-- 全部-系统通知 -->
            <div class="notice">
              <img src="@image/xttz.png" alt="" />
              <div class="noticeRight">
                <div class="noticeRight-one">
                  <span>系统通知</span>
                  <span class="time">2020-12-30 11:10</span>
                </div>
                <div class="details">
                  <span>您收到一个调研的邀请，</span>
                  <span class="click">点击查看详情</span>
                </div>
              </div>
            </div>
            <!-- 全部-评论 -->
            <div class="reply">
              <img src="@image/tx.jpg" alt="" />
              <div class="replyRight">
                <div class="rightOne">
                  <span class="name">张三三</span>
                  <span>回复了</span>
                  <span class="rightOne-time">2020-12-30 11:10</span>
                </div>
                <span class="replyRight-subTitle"
                  >说的很不错，赶紧收藏起来</span
                >
                <div class="replyComments">
                  <img src="@image/pinglun1.jpg" alt="" />
                  <span class="comment">脑血管狭窄引起头疼应该注意些什么</span>
                </div>
              </div>
            </div>
            <!-- 关注 -->
            <div class="attention">
              <img src="@image/tx.jpg" alt="" />
              <span class="attentionName">张三三</span>
              <span>关注了你</span>
              <span class="attentionTime">2020-12-30 11:10</span>
            </div>
            <span class="prompt">没有更多数据了</span>
          </el-tab-pane>
          <!-- 系统通知 -->
          <el-tab-pane label="系统通知" name="systemNotification">
            <div class="notice" v-for="(item, index) in 3" :key="index">
              <img src="@image/xttz.png" alt="" />
              <div class="noticeRight">
                <div class="noticeRight-one">
                  <span>系统通知</span>
                  <span class="time">2020-12-30 11:10</span>
                </div>
                <div class="details">
                  <span>您收到一个调研的邀请，</span>
                  <span class="click">点击查看详情</span>
                </div>
              </div>
            </div>
            <span class="prompt">没有更多数据了</span>
          </el-tab-pane>
          <el-tab-pane label="评论" name="comment">
            <!-- 评论 -->
            <div class="reply" v-for="(item, index) in 3" :key="index">
              <img src="@image/tx.jpg" alt="" />
              <div class="replyRight">
                <div class="rightOne">
                  <span class="name">张三三</span>
                  <span>回复了</span>
                  <span class="rightOne-time">2020-12-30 11:10</span>
                </div>
                <span class="replyRight-subTitle"
                  >说的很不错，赶紧收藏起来</span
                >
                <div class="replyComments">
                  <img src="@image/pinglun1.jpg" alt="" />
                  <span class="comment">脑血管狭窄引起头疼应该注意些什么</span>
                </div>
              </div>
            </div>
            <span class="prompt">没有更多数据了</span>
          </el-tab-pane>
          <el-tab-pane label="粉丝" name="fans">
            <!-- 粉丝 -->
            <div class="attention" v-for="(item, index) in 4" :key="index">
              <img src="@image/tx.jpg" alt="" />
              <span class="attentionName">张三三</span>
              <span>关注了你</span>
              <span class="attentionTime">2020-12-30 11:10</span>
            </div>
            <span class="prompt">没有更多数据了</span>
          </el-tab-pane>
          <el-tab-pane label="点赞" name="like">
            <!-- 点赞 -->
            <!-- <div class="reply" v-for="(item, index) in 3" :key="index">
              <img src="@image/tx.jpg" alt="" />
              <div class="replyRight">
                <div class="rightOne">
                  <span class="name">张三三</span>
                  <span>点赞了</span>
                  <span class="rightOne-time">2020-12-30 11:10</span>
                </div>
                <span class="replyRight-subTitle"
                  >说的很不错，赶紧收藏起来</span
                >
                <div class="replyComments">
                  <img src="@image/pinglun1.jpg" alt="" />
                  <span class="comment">脑血管狭窄引起头疼应该注意些什么</span>
                </div>
              </div>
            </div>
            <span class="prompt">没有更多数据了</span> -->
            <!-- 缺省页 -->
            <div class="defaultPage">
              <img src="@image/qsy.png" alt="" />
              <span class="temporarily">暂时还没有人点赞哦~</span>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "all",
    };
  },
  methods: {
    handleClick(tab, event) {
      tab, event;
    },
  },
};
</script>

<style lang='scss' scoped>
@import "@assets/css/themeColor.scss";

::v-deep {
  .el-tab-pane {
    display: flex;
    flex-direction: column;
  }
  .el-tabs .el-tabs__item.is-active {
    color: $--themeColor;
    font-weight: 400;
    transform: scale(1);
  }
  .el-tabs .el-tabs__header {
    margin-bottom: 0;
  }
  .el-tabs__content {
    padding: 30px;
    box-sizing: border-box;
  }
  .el-tabs__nav-wrap {
    background: #ffffff;
    padding: 15px 0 11px 25px;
    box-sizing: border-box;
  }
}
.page {
  background: #f2f2f2;
  > section {
    display: flex;
    flex-direction: column;
    > .title {
      font-size: 18px;
      color: #333333;
      font-weight: bold;
      margin: 30px 0 22px 0;
    }
    > .content {
      background: #ffff;
      width: 1200px;
      .notice {
        display: flex;
        flex-direction: row;
        align-content: center;
        border-bottom: 1px solid #f2f2f2;
        padding-bottom: 30px;
        box-sizing: border-box;
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0;
        }
        > img {
          width: 50px;
          height: 50px;
          object-fit: cover;
          margin-right: 20px;
        }
        > .noticeRight {
          display: flex;
          flex-direction: column;
          font-size: 16px;
          margin-top: 3px;
          color: #333333;
          min-width: 1070px;
          > .noticeRight-one {
            display: flex;
            flex-direction: row;
            > .time {
              margin-left: auto;
              color: #8f8f8f;
            }
          }
          > .details {
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            font-size: 18px;
            > .click {
              color: #352b5c;
            }
          }
        }
      }
    }
    .reply {
      display: flex;
      flex-direction: row;
      color: #333333;
      margin-bottom: 30px;
      padding-bottom: 30px;
      box-sizing: border-box;
      border-bottom: 1px solid #f2f2f2;
      > img {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 50%;
      }
      > .replyRight {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        > .rightOne {
          display: flex;
          flex-direction: row;
          font-size: 16px;
          color: #8f8f8f;
          > .name {
            color: #333333;
            margin-right: 10px;
          }
          > .rightOne-time {
            margin-left: auto;
          }
        }
        > .replyComments {
          display: flex;
          flex-direction: row;
          width: 1070px;
          height: 60px;
          background: #f2f2f2;
          > img {
            width: 60px;
            height: 60px;
            object-fit: cover;
          }
          > .comment {
            font-size: 18px;
            line-height: 60px;
            margin-left: 20px;
          }
        }
        > .replyRight-subTitle {
          font-size: 18px;
          margin: 10px 0;
        }
      }
    }
    .attention {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 30px;
      color: #8f8f8f;
      font-size: 18px;
      padding-bottom: 30px;
      box-sizing: border-box;
      border-bottom: 1px solid #f2f2f2;
      > img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
      > .attentionName {
        margin: 0 10px 0 20px;
        color: #333333;
      }
      > .attentionTime {
        font-size: 16px;
        margin-left: auto;
      }
    }
    .defaultPage {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
      font-size: 18px;
      color: #333333;
      > img {
        width: 374px;
        height: 351px;
        object-fit: cover;
        margin-bottom: 5px;
      }
    }
    .prompt {
      color: #8f8f8f;
      font-size: 18px;
      margin: 0 auto;
    }
  }
}
</style>